
export enum OrgRoleType {
  ADMIN = "admin",
  DOCTOR = "doctor",
  CARE_NAVIGATOR = "professional caregiver",
}


export const OrgRoleTypeToLabel: Record<OrgRoleType, string> = {
  [OrgRoleType.ADMIN]: "Administrator",
  [OrgRoleType.DOCTOR]: "Doctor/Nurse",
  [OrgRoleType.CARE_NAVIGATOR]: "Care Navigator",
};

export enum NetworkRoleType {
  DOCTOR = OrgRoleType.DOCTOR,
  CARE_NAVIGATOR = OrgRoleType.CARE_NAVIGATOR,
  ADMIN = OrgRoleType.ADMIN,
  PRIMARY_CAREGIVER = "primary caregiver",
  CAREGIVER = "caregiver",
  FAMILY_MEMBER = "family member",
}

export const NetworkRoleTypeToLabel: Record<NetworkRoleType, string> = {
  [NetworkRoleType.DOCTOR]: OrgRoleTypeToLabel[OrgRoleType.DOCTOR],
  [NetworkRoleType.CARE_NAVIGATOR]: OrgRoleTypeToLabel[OrgRoleType.CARE_NAVIGATOR],
  [NetworkRoleType.ADMIN]: OrgRoleTypeToLabel[OrgRoleType.ADMIN],
  [NetworkRoleType.PRIMARY_CAREGIVER]: "Primary Caregiver",
  [NetworkRoleType.CAREGIVER]: "Caregiver",
  [NetworkRoleType.FAMILY_MEMBER]: "Family Member",
};

export const NETWORK_ROLE_OPTIONS: { value: NetworkRoleType; label: string }[] = [
  { value: NetworkRoleType.DOCTOR, label: NetworkRoleTypeToLabel[NetworkRoleType.DOCTOR] },
  { value: NetworkRoleType.CARE_NAVIGATOR, label: NetworkRoleTypeToLabel[NetworkRoleType.CARE_NAVIGATOR] },
  { value: NetworkRoleType.ADMIN, label: NetworkRoleTypeToLabel[NetworkRoleType.ADMIN] },
  { value: NetworkRoleType.PRIMARY_CAREGIVER, label: NetworkRoleTypeToLabel[NetworkRoleType.PRIMARY_CAREGIVER] },
  { value: NetworkRoleType.CAREGIVER, label: NetworkRoleTypeToLabel[NetworkRoleType.CAREGIVER] },
  { value: NetworkRoleType.FAMILY_MEMBER, label: NetworkRoleTypeToLabel[NetworkRoleType.FAMILY_MEMBER] },
];

export const ORG_ROLE_OPTIONS: { value: OrgRoleType; label: string }[] = [
  { value: OrgRoleType.DOCTOR, label: OrgRoleTypeToLabel[OrgRoleType.DOCTOR] },
  { value: OrgRoleType.CARE_NAVIGATOR, label: OrgRoleTypeToLabel[OrgRoleType.CARE_NAVIGATOR] },
  { value: OrgRoleType.ADMIN, label: OrgRoleTypeToLabel[OrgRoleType.ADMIN] },
];