import { useUserRolesInNetwork } from "../../../backend/resources/userRole";
import { NetworkRoleType } from "../../../backend/resources/userRole/types";
import { Checkbox } from "../../Checkbox";

interface AssessmentCaregiverSelectionPageProps {
  selectedCaregiver: string | undefined;
  setSelectedCaregiver: (value: string) => void;
}

export default function AssessmentCaregiverSelectionPage({ selectedCaregiver, setSelectedCaregiver }: AssessmentCaregiverSelectionPageProps) {
  const { data: userRoles } = useUserRolesInNetwork();
  return <div>
    <div className="flex flex-col gap-5">
      {userRoles?.filter((userRole) => [NetworkRoleType.CAREGIVER, NetworkRoleType.PRIMARY_CAREGIVER]
        .includes(userRole.role as NetworkRoleType))
        .map((userRole) => (
          <div className="flex gap-5 items-center">
            <Checkbox
              isRounded
              isChecked={selectedCaregiver === userRole.user.id}
              onCheck={() => setSelectedCaregiver(userRole.user.id)}
            />
            <p>{`${userRole.user.first_name} ${userRole.user.last_name}`}</p>
          </div>
        ))}
    </div>
  </div>;
}