import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../backend/queryKeys";
import type { Network } from "../../backend/resources/network/network";
import type {
  Organization,
  OrganizationRole,
} from "../../backend/resources/orgRole";
import type { User } from "../../backend/resources/user";
import type { UserRole } from "../../backend/resources/userRole";
import { supabase } from "../../clients/supabaseClient";
import { useActiveOrganizationId } from "../../state/organization/organization";
import { OrgRoleType } from "../../backend/resources/userRole/types";

type UserOrgCarespace = User & {
  organization_role: (OrganizationRole & { organization: Organization })[];
} & {
  user_role: (UserRole & { network: Network })[];
};

export function useOrgAndCarespaceIdentitiesForUser(userId?: string) {
  // carespace and org identities
  const { isLoading, error, data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.orgAndCarespaceIdentities, { userId }],
    queryFn: () => fetchCarespaceAndOrgIdentitiesByUserId(userId),
    refetchOnWindowFocus: false,
  });

  return {
    // query obj
    isLoading,
    error,
    data,
    isFetching,
    refetch,
    // derived
    carespaceNames: data?.user_role?.map((role) => role?.network?.name),
    orgNames: data?.organization_role?.map((role) => role?.organization?.name),
  };
}

async function fetchCarespaceAndOrgIdentitiesByUserId(userId?: string) {
  if (!userId) {
    return null;
  }

  const { data, error } = await supabase
    .from("user")
    .select(
      "*, organization_role(*, organization(*)), user_role(*, network(*))"
    )
    .eq("id", userId)
    .returns<UserOrgCarespace[]>();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data?.[0];
}

export const useSearchOrgMembers = (query: string, role_type: OrgRoleType | undefined) => {
  const orgId = useActiveOrganizationId()
  return useQuery({
    queryKey: [QUERY_KEYS.orgMembers, { query, role_type }],
    queryFn: async () => {
      const { data, error } = await searchOrgMembers({
        orgId,
        searchTerm: "",
        role_type,
      });
      return data
    },
    refetchOnWindowFocus: false,
  });
}



export async function searchOrgMembers({
  orgId,
  searchTerm,
  role_type
}: {
  orgId: string | null | undefined;
  searchTerm: string | null;
  role_type: OrgRoleType | undefined;
}) {
  let data, error;
  if (orgId) {
    const { data: matchingMembersData, error: searchError } =
      await supabase.rpc("search_org_members", {
        org_id: orgId,
        search_term: searchTerm || "",
        role_type,
      });
    data = matchingMembersData;
    error = searchError;
  }

  return { data, error };
}