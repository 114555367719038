import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useOrgs } from "../../backend/resources/orgRole";
import {
  useActiveUserAdlo,
  useCareBinderName,
} from "../../backend/resources/userAdlo";
import { useActiveUserRole } from "../../backend/resources/userRole";
import { NetworkRoleType } from "../../backend/resources/userRole/types";
import { useActiveNetworkId } from "../../state/network/network";
import AdloComponent from "../AdloComponent/AdloComponent";
import BackButton from "../BackButton/BackButton";
import CarePlan from "../CarePlan/CarePlan";
import { LoadingSpinner } from "../LoadingSpinner";
import { NanasDay } from "../NanasDay";
import { PageContainer } from "../PageContainer";
import { PageMainHeaderWithNetworkName } from "../PageMainHeader/PageMainHeaderWithNetwork";
import Tabs from "../Tabs/Tabs";
import { AssessmentsTab } from "./components/AssessmentsTab";
import CarePlanStatusIcon from "./components/CarePlanStatusIcon";
import DiagnosisTab from "./components/DiagnosisTab";

export enum CareBinderTabs {
  CARE_PLAN = "Care Plan",
  ASSESSMENTS = "Assessments",
  MEDICATIONS = "Medications",
  DIAGNOSIS = "Diagnosis",
}

export default function CareBinder() {
  const [currentTab, setCurrentTab] = useState<CareBinderTabs>(
    CareBinderTabs.CARE_PLAN
  );

  // Queries
  const flags = useFlags();
  const { careBinderName, isLoadingCareBinderName } = useCareBinderName();
  const userAdlo = useActiveUserAdlo();
  const { userRole } = useActiveUserRole();
  const { hasCareCentralAccess } = useOrgs();
  const networkId = useActiveNetworkId();

  // Family Members only have access to assessments tab
  useEffect(() => {
    if (userRole?.role === NetworkRoleType.FAMILY_MEMBER && currentTab !== CareBinderTabs.ASSESSMENTS) {
      setCurrentTab(CareBinderTabs.ASSESSMENTS);
    }
  }, [userRole]);

  // +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+

  return (
    <PageContainer>
      {hasCareCentralAccess && networkId || userRole?.role === NetworkRoleType.FAMILY_MEMBER ? (
        <BackButton className="mb-2"/>
      ) : null}
      {isLoadingCareBinderName ? (
        <LoadingSpinner className="h-8 w-8" />
      ) : (
        <PageMainHeaderWithNetworkName text={careBinderName} />
      )}
      <div className="flex flex-col gap-4 max-w-4xl">
        <Tabs
          className="mt-3"
          tabs={getTabsForUserRole(userRole?.role as NetworkRoleType, flags.medication_management)}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
       
          <div className="flex flex-col gap-5 lg:flex-row items-end justify-end">
            <AdloComponent userAdlo={userAdlo} />
            <CarePlanStatusIcon />
          </div> 
       
        {currentTab === CareBinderTabs.CARE_PLAN ? (
          <CarePlan />
        ) : null}
        {currentTab === CareBinderTabs.ASSESSMENTS ? (
          <AssessmentsTab />
        ) : null}
        {currentTab === CareBinderTabs.MEDICATIONS ? <NanasDay /> : null}
        {currentTab === CareBinderTabs.DIAGNOSIS ? <DiagnosisTab /> : null}

      </div>
    </PageContainer>
  );
}

function getTabsForUserRole(userRole?: NetworkRoleType, isMedicationManagementOn: boolean = false) {
  if (userRole === NetworkRoleType.FAMILY_MEMBER) {
    return [CareBinderTabs.ASSESSMENTS];
  } else {
    let tabs = Object.values(CareBinderTabs);
    return tabs;
  }
}
