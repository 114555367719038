import { Check, ChevronsUpDown } from "lucide-react"
import { useEffect, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { z } from "zod"
import { OrgRoleType } from "../../../backend/resources/userRole/types"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { searchOrgMembers } from "../../../hooks/orgMember/orgMember"
import { capitalizeFirstLetter } from "../../../utils"
import { Button } from "../../ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../ui/command"
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from "../../ui/form"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../ui/popover"
import { ScrollArea } from "../../ui/scroll-area"
import { cn } from "../../utils/cn"
import { orgMemberSchema } from "./AddOrgMemberNavigatorForm"

export type OrgMember = {
  organization_id: string;
  org_role: string;
  user_id: string;
  first_name: string;
  last_name: string;
  cell_number: string;
  email: string;
};

export function OrgMemberCombobox({
  form,
  orgId,
  role_type,
  disabled
}: {
  form: UseFormReturn<z.infer<typeof orgMemberSchema>>,
  orgId: string|undefined
  role_type: OrgRoleType | undefined
  disabled?: boolean
}) {

  const [options, setOptions] = useState<OrgMember[]>([]);
  const [networkCallInProgress, setNetworkCallInProgress] = useState(false);
  const getFullNameForSelectedOrgMember = (user_id: string) => {
    const orgMember = options.find(
      (orgMember) => orgMember.user_id === user_id
    )
    if (orgMember) {
      return `${capitalizeFirstLetter(orgMember?.first_name)} ${capitalizeFirstLetter(orgMember?.last_name)}`
    } else if (networkCallInProgress) {
      return <LoadingSpinner className="w-4 h-4" />
    } else {
      return ""
    }
  }

  useEffect(() => {
    const fetchMatchingOrgMembers = async () => {
      setNetworkCallInProgress(true);
      const { data, error } = await searchOrgMembers({
        orgId: orgId,
        searchTerm: "",
        role_type,
      });
      if (!error && !!data) {
        setOptions(data);
      }
      setNetworkCallInProgress(false);
    };

    fetchMatchingOrgMembers();
  }, [role_type]);

  return (
    <FormField
      control={form.control}
      name={"orgMember"}
      render={({ field }) => (
        <FormItem className="flex flex-col w-full">
          <Popover modal={true}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={disabled}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "w-full justify-between",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  {field.value
                    ? getFullNameForSelectedOrgMember(field.value.user_id)
                    : "Select member"}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-full">

              <Command className=" w-full">
                <CommandInput
                  placeholder="Search member..."
                />
                {networkCallInProgress ? (
                  <LoadingSpinner className="w-6 h-6"
                  />
                ) : null}
                <CommandEmpty>No member found.</CommandEmpty>
                <ScrollArea className="w-full flex max-h-[20vh] flex-col" type="always">
                  <CommandGroup className=" w-full">
                    {options.map((orgMember) => (
                      <CommandItem
                        value={`${orgMember.first_name} ${orgMember.last_name}`}
                        key={`${orgMember.first_name} ${orgMember.last_name}`}
                        onSelect={() => {
                          form.setValue("orgMember", orgMember)
                        }}
                        disabled={disabled}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            orgMember.user_id === field.value?.user_id
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {`${capitalizeFirstLetter(orgMember?.first_name)} ${capitalizeFirstLetter(orgMember?.last_name)}`}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </ScrollArea>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}