import type { ReactNode } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Popup } from "../../shared/ui/popup";
import { PopupOrSheet } from "../../shared/ui/popup-bottom-sheet";
import { ResponsiveModal } from "../../shared/ui/responsive-modal";
import type { ModalProps } from "../ModalView/ModalView";
import ModalView from "../ModalView/ModalView";
import { BottomSheet } from "../Sheet";

/**
 * @deprecated This component is deprecated and will be removed in a future version.
 * 
 * For most cases, use ResponsiveModal:
 * @see ResponsiveModal
 * 
 * For cases where you need a bottom sheet on mobile, use PopupOrSheet:
 * @see PopupOrSheet
 */
export default function ResponsivePopup({
  isOpen,
  onClose,
  children,
  showPopupAsModalInMobile,
  footerButtons,
  ...modalProps
}: {
  isOpen: boolean;
  onClose: any;
  children: ReactNode;
  footerButtons?: ReactNode,
  showPopupAsModalInMobile?: boolean;
} & ModalProps) {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    if (showPopupAsModalInMobile) {
      return (
        <ModalView
          isOpen={isOpen}
          onClose={onClose}
          {...modalProps}>
          {children}
        </ModalView>
      );
    }
    return (
      <BottomSheet
        isOpen={isOpen}
        onClose={onClose}>
        {children}
      </BottomSheet>
    );
  } else if (isOpen) {
    return <Popup isOpen={isOpen} onClose={onClose} footerButtons={footerButtons}>{children}</Popup>;
  } else {
    return null;
  }
}
