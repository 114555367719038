import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import * as z from "zod";
import { NotificationType, sendNotification } from "../../backend/functions";
import {
  useActiveOrg,
} from "../../backend/resources/orgRole";
import {
  OrganizationInvitation,
  OrganizationInvitationInsert,
  useInsertOrganizationInvitation,
} from "../../backend/resources/organizationInvitations/organizationInvitation";
import { ORG_ROLE_OPTIONS, OrgRoleType } from "../../backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "../../components/ButtonWithIcon";
import { PhoneNumberFormInput } from "../../components/PhoneNumberInput/PhoneNumberInput";
import { useActiveOrganizationId } from "../../state/organization/organization";
import { useUserStore } from "../../state/user";
import { Checkbox } from "../ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRoot,
  FormSection,
  FormSectionHeader,
} from "../ui/form";
import { Input } from "../ui/input";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { ResponsiveModal } from "../ui/responsive-modal";
import { MultiPageFormProps, PhoneNumberSchema, ResponsiveModalWizardProps } from "./types";

/**
 * schema
 */
export const addToOrganizationFormSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email("Please enter a valid email."),
  cell_number: PhoneNumberSchema,
  org_role: z.string(),
  is_superuser: z.boolean(),
});

/**
 * hook
 */
export const useAddToOrganizationForm = () =>
  useForm<z.infer<typeof addToOrganizationFormSchema>>({
    resolver: zodResolver(addToOrganizationFormSchema),
    defaultValues: {
      is_superuser: false,
    },
    mode: "onSubmit",
  });

export type AddToOrganizationFormHook = UseFormReturn<
  z.infer<typeof addToOrganizationFormSchema>
>;

/**
 * form
 */
export const addToOrganizationFormId = "add-to-organization-form";

export function AddToOrganizationFormComponent(
  props: MultiPageFormProps<z.infer<typeof addToOrganizationFormSchema>>
) {
  const { form } = props;
  const orgRole = form.watch('org_role'); // watch the orgRole field
  return (
    <Form {...form}>
      <FormRoot id={addToOrganizationFormId}>
        {/* org member details */}
        <FormSection>
          <FormSectionHeader title="Organization member details" />
          {/* first row: name */}
          <div className="grid grid-cols-[2fr,3fr] gap-2">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* second row: email */}
          <div className="grid grid-cols-[3fr,2fr]">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* third row: cell */}
          <div className="grid grid-cols-[3fr,2fr]">
            <FormField
              control={form.control}
              name="cell_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Cell</FormLabel>
                  <FormControl>
                    <PhoneNumberFormInput
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* fourth row: role */}
          <div className="grid grid-cols-[3fr,2fr]">
            <FormField
              control={form.control}
              name="org_role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Role</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1">
                      {ORG_ROLE_OPTIONS.map((option) => (
                        <FormItem
                          key={option.value}
                          className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value={option.value} />
                          </FormControl>
                          <FormLabel>{option.label}</FormLabel>
                        </FormItem>
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* fifth row: access */}
          <div className="grid grid-cols-[3fr,2fr]">
            <FormField
              control={form.control}
              name="is_superuser"
              render={({ field }) => (
                <FormItem>
                  <FormItem>
                    <FormLabel>Additional Access</FormLabel>
                  </FormItem>
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value || orgRole === OrgRoleType.ADMIN}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Administrative Privileges</FormLabel>
                  </FormItem>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </FormSection>
      </FormRoot>
    </Form>
  );
}

/**
 * container
 */

export function AddToOrganizationForm({ onClose }: ResponsiveModalWizardProps) {
  const TITLE = "Add To Organization";
  const form = useAddToOrganizationForm();
  const formValues = form.watch();
  const [isSending, setIsSending] = useState(false);

  const authUser = useUserStore((state) => state.user);
  const { data: organization } = useActiveOrg();
  const organizationId = useActiveOrganizationId();

  const insertInvitation = useInsertOrganizationInvitation().mutateAsync;

  const handleSendInvitation = async (
    validatedForm: z.infer<typeof addToOrganizationFormSchema>
  ) => {
    setIsSending(true);

    // guard: if organizationId is null, we cannot add org member
    if (!organizationId) return;

    const newInvitation: OrganizationInvitationInsert | OrganizationInvitation =
    {
      organization_id: organizationId,
      invited_email: validatedForm.email,
      first_name: validatedForm.first_name,
      last_name: validatedForm.last_name,
      role_type: validatedForm.org_role,
      cell_number: validatedForm.cell_number,
      is_superuser:
        validatedForm.is_superuser ||
        validatedForm.org_role === OrgRoleType.ADMIN, // TODO add a checkbox
    };

    let data: OrganizationInvitation | undefined = await insertInvitation(
      newInvitation
    );

    if (data) {
      // Create URL object. We use the location origin so that it works for all enviromnets
      await sendNotification(data.id, NotificationType.ORGANIZATION_INVITATION)
    }

    setIsSending(false);
  };

  return (
    <ResponsiveModal
      isOpen={true}
      closeText="Back"
      title={TITLE}
      isNetworkCallInProgress={isSending}
      onClose={() => {
        onClose();
        form.reset();
      }}
      footerButtons={
        <>
          <ButtonWithIcon
            text="Cancel"
            icon={IconOption.CANCEL}
            onClick={() => {
              onClose();
              form.reset();
            }}
          />
          <ButtonWithIcon
            text="Done"
            icon={IconOption.CHECKMARK}
            onClick={async () => {
              await form.handleSubmit((values) => {
                handleSendInvitation(values);
                onClose();
                form.reset();
              })();
            }}
            disabled={
              Object.values(formValues).some(
                (value) => value === "" || value === undefined
              ) || isSending
            }
          />
        </>
      }>
      <AddToOrganizationFormComponent form={form} />
    </ResponsiveModal>
  );
}
